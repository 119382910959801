import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import {
  parseJSON,
  differenceInDays,
  differenceInSeconds,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';

export default class RelativeTime extends Helper {
  @service intl;

  compute([time]) {
    if (time) {
      // console.log(time);
      if (typeof time.getTime == 'function') {
        time = time;
      } else {
        time = parseJSON(time);
      }

      let timeDiff = differenceInDays(time, new Date());

      let unit = 'day';

      if (Math.abs(timeDiff) > 365) {
        timeDiff = differenceInYears(time, new Date());
        unit = 'year';
      } else if (Math.abs(timeDiff) >= 30) {
        timeDiff = differenceInMonths(time, new Date());
        unit = 'month';
      } else if (Math.abs(timeDiff) >= 7 && Math.abs(timeDiff) < 30) {
        timeDiff = differenceInWeeks(time, new Date());
        unit = 'week';
      } else if (Math.abs(timeDiff) > 1) {
        timeDiff = differenceInDays(time, new Date());
        unit = 'day';
      } else {
        let hourDiff = differenceInHours(time, new Date());
        let minuteDiff = differenceInMinutes(time, new Date());
        let secondsDiff = differenceInSeconds(time, new Date());

        if (Math.abs(minuteDiff) <= 60 && Math.abs(minuteDiff) >= 1) {
          timeDiff = minuteDiff;
          unit = 'minute';
        } else if (Math.abs(hourDiff) >= 1) {
          timeDiff = hourDiff;
          unit = 'hour';
        } else if (Math.abs(secondsDiff) <= 60) {
          timeDiff = secondsDiff;
          unit = 'second';
          return 'just now';
        }
      }

      return this.intl.formatRelative(timeDiff, {
        unit,
      });
    }
  }
}
